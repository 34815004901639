import axios from "axios";
import { getQrcodeApiDomain } from "../../config";

const API_URL_CRUD = (getQrcodeApiDomain()+ "/api/");

export default function qrcodeimg(name: string, address: string, postcode: string) {
    return axios.put(API_URL_CRUD + "qrcodeimg", {
      name,
      address,
      postcode
      });
     
    }

