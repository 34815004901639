import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import Input from '../../common/Input';

import { ContentBlockProps } from "../../common/ContentBlockCss/types";
import { SvgIcon } from "../../common/SvgIcon";
import {
  ContentSection,
  Content,
  ContentWrapper,
  ServiceWrapper,
  MinTitle,
  MinPara,
  StyledRow,
  Span,
  Large,
  FormGroup,
  StyledButton
} from "../../common/ContentBlockCss/styles";
import { Button } from "../../common/Button";
import CodeScan from "../CodeScan";
import { QrReader } from "react-qr-reader";
import  React, { useState, useEffect } from "react";
import codelist from "../../services/CodeList";
import Session from 'supertokens-web-js/recipe/session';
import {Root, Qrcodelist} from "./types"
import { Styles } from "src/styles/styles";
import { AxiosResponse } from "axios";


const AdminContentBlock = ({
  title,
  content,
  t,
  id,
  direction
}: ContentBlockProps) => {



    const initialValues: Root = {
        qrcodelist:{
            name: "",
            address: "",
            postcode:"",
            content: [],
            awardcounter:0,
            }
      };
      
  
  const [name, setName] = useState("");
  const [loading, setLoading]   = useState(false);
  const [codes, setCodes] = useState<{values: Root}>({
    values: { ...initialValues },
  });



const handleChange = (
    
 event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
 event.persist();
 setName(event.target.value);
}
 
 /* async function senddataold() {
 useEffect(() => {
// apicall()
//let qrcode = "C8E265328CB";
}, [codes]);
 console.log("codes stsate ",codes)
}*/

const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    setCodes(() => ({
        values: { ...initialValues },
      }));
    event.preventDefault();
    let res: AxiosResponse<Root, any>;
    console.log("name is :",name)
    try {
    res = await codelist(name);
    //if (JSON.stringify(res.status) == "200") {   
    if (res.status == 200) {
    //let newUsercodes = Object.assign(usercodes, res.data);
    //console.log("data", res.data.qrcodelist.address);
      const  pco = res.data.qrcodelist.postcode;
     //  setCodes((prevCodes) =>({
     // ...prevCodes,
     // postcode : pco
     // }))
       setCodes(() => ({
        values: {
          ...res.data
      }}));
           
        // console.log("received data is ", codes.values)
    }
    }
   catch (err: any) {
    window.alert(` user ${name} is not found`);
    console.log(err);
   }
   event.target.reset();
   }

   // useEffect( () => {
   
    
  //  }, [])


    //if (window.confirm(`is the option ${qrcode} confirmed`))
    //window.alert("now you need drop down popup menu too approve the rerward")
 // }, [codes]);


  
  /** Fetch data from an API
  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/users')
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
      <h1>User List</h1>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            <strong>{user.name}</strong> - {user.email}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default App;
*/ 
  
 //useEffect( () => {
   // senddata();
   // }, [data])

return (
    <>
    <ContentSection> 
    <Styles />  
      <StyledRow
        justify="space-between"
        align="middle"
        id={id}
        direction={direction}
      >
        <Col lg={22} md={22} sm={22} xs={24}>
          <ContentWrapper>
            <h6>{t(title)}</h6>
            <Content>{t(content)}</Content>
              <FormGroup autoComplete="off" onSubmit={handleSubmit}>
                <Input
                  type="text"
                  name=""
                  placeholder="Your Email"
                  value={name || ""}
                  onChange={handleChange} />  
                <StyledButton name="submit">{("Submit")}</StyledButton>
                </FormGroup>
          </ContentWrapper>
        </Col>
      </StyledRow>
      <div>
     <p></p>
     </div>
     <ul>
        {codes.values.qrcodelist.name}
        <div></div>
        {codes.values.qrcodelist.address}
        <div></div>
        {codes.values.qrcodelist.postcode}
        <div></div>
        {codes.values.qrcodelist.awardcounter}
        <div></div>
        {codes.values.qrcodelist.content.map((code, index) => (
          <li key={index}>
            {code}
          </li>
        ))}
      </ul>
    </ContentSection>
    <div>
      </div>
    </>
     
  );


};

export default withTranslation()(AdminContentBlock);
