import { sendPasswordResetEmail } from "supertokens-web-js/recipe/emailpassword";

 async function RecoverPass(email: string) {
    try {
        let response = await sendPasswordResetEmail({
            formFields: [{
                id: "email",
                value: email
            }]
        });

        if (response.status === "FIELD_ERROR") {
            // one of the input formFields failed validation
            response.formFields.forEach(formField => {
                if (formField.id === "email") {
                    // Email validation failed (for example incorrect email syntax).
                    window.alert(formField.error)
                }
            })
        } else if (response.status === "PASSWORD_RESET_NOT_ALLOWED") {
            // this can happen due to automatic account linking. Please read our account linking docs
        } else {
            // reset password email sent.
            window.alert("Please check your email for the password reset link")
            window.location.assign("/login"); 
        }
    } catch (err: any) {
        if (err.isSuperTokensGeneralError === true) {
            // this may be a custom error message sent from the API by you.
            window.alert(err.message);
        } else {
            window.alert("Oops! Something went wrong.");
        }
    }
}
export default RecoverPass;