import ThirdParty, { Google, Facebook } from "supertokens-auth-react/recipe/thirdparty";
import { ThirdPartyPreBuiltUI } from "supertokens-auth-react/recipe/thirdparty/prebuiltui";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";

export function getApiDomain() {
    //const apiPort = 3336;
     const apiPort =  8082;
    //const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
    const apiUrl =  `http://localhost:${apiPort}`;
    
    return apiUrl;
}


export function getQrcodeApiDomain() {
   const apiPort = 3336;
    //const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
    const apiUrl =  "https://code-recycle.com";
    
    return apiUrl;
}

export function getWebsiteDomain() {
    const websitePort = process.env.REACT_APP_WEBSITE_PORT || 3000;
    const websiteUrl = process.env.REACT_APP_WEBSITE_URL || `http://localhost:${websitePort}`;
    return websiteUrl;
}

export const SuperTokensConfig = {
    appInfo: {
       // appName: "SuperTokens Demo App",
       // apiDomain: getApiDomain(),
       // websiteDomain: getWebsiteDomain(),
       appName: "code-recycle",
       apiDomain: "https://code-recycle.com/auth",
       websiteDomain: "https://code-recycle.com",
    },
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    useShadowDom: false,
    recipeList: [
        EmailPassword.init(),
        ThirdParty.init({
            signInAndUpFeature: {
               // providers: [Github.init(), Google.init(), Apple.init(), Twitter.init()],
               providers: [Google.init(), Facebook.init()],
            },
        }),
        EmailPassword.init(),
        Session.init(),
    ],
};

export const recipeDetails = {
    docsLink: "https://supertokens.com/docs/thirdpartyemailpassword/introduction",
};

export const PreBuiltUIList = [ThirdPartyPreBuiltUI, EmailPasswordPreBuiltUI];

export const ComponentWrapper = (props: { children: JSX.Element }): JSX.Element => {
    return props.children;
};
