import axios from "axios";
import { getQrcodeApiDomain } from "../../config";
import { AxiosResponse } from 'axios';
import { Root, Qrcodelist } from "src/components/AdminContentBlock/types";

const API_URL_CRUD = (getQrcodeApiDomain()+ "/api/");

export default function codelist(name: string): Promise<AxiosResponse<Root>>{
    return axios.put<Root>(API_URL_CRUD + "codelist", {
        name
      });
     
    }