import Container from "../../common/Container";
import PartnerContentBlock from "../../components/PartnerContentBlock";
import CodeScan from "../../components/CodeScan";
import UserContentBlockContent from "../../content/UserContentBlockContent.json";
import CodeScanContent from "../../content/CodeScanContent.json";

const PartnerPage = () => {

    return(
      <Container>
 <PartnerContentBlock
        direction="left"
        title={UserContentBlockContent.title}
        content={UserContentBlockContent.text}
        icon=""
        id="qr code"
      />

      </Container>

 
    )
}

export default PartnerPage; 