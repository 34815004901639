import Session from 'supertokens-web-js/recipe/session';

async function getuser() {
    if (await Session.doesSessionExist()) {
          let accessTokenPayload = await Session.getUserId;
          let loggedname = accessTokenPayload.name;
          console.log("name is", loggedname);
          return loggedname;
    }
    else 
    return "anymonus";
}
export default  getuser