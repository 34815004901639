import Input from '../../common/Input';
import { Container, StyledButton, FormGroup, Span, Large} from "../../common/ContentBlockCss/styles"
import { Styles } from "../../styles/styles";
import RecoverPass from "../../common/RecoverPass";
import  {LoadingSpinner}  from "../../common/LoadingSpinner"
import { useState } from 'react';


function PassRecov() {

const [emailvalid, setEmailvalid] = useState(false);
const [email, setEmail] = useState("");
const [loading, setLoading]   = useState(false);
    
const ValidationEmail = () => {
       let errorMessage:string = "";   
      if (email && !/\S+@\S+\.\S+/.test(email)) {
      errorMessage = "Email address is invalid";
      setEmailvalid(false)
      }
      else {setEmailvalid(true)}
      return <Span>{errorMessage}</Span>;
      };
   

    const handleChange = (
    
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
     event.persist();
        setEmail(event.target.value);
    }
    const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        RecoverPass(email);
        setLoading(false);
        event.target.reset();
        
    }

  const Spinner = () =>{
   
if (loading)
  {return <LoadingSpinner/>;}
    
  }
       
    return (
        <>
        <Container>
        <Styles />
        <FormGroup autoComplete="off" onSubmit={handleSubmit}>
                <Input
                  type="text"
                  name="Recover Password"
                  placeholder="Your Email"
                  value={email || ""}
                  onChange={handleChange} />
                  <ValidationEmail />    
                  <div>          
                </div>
                <StyledButton name="submit"  disabled={!emailvalid}>{("Submit")}</StyledButton>
                </FormGroup>
                <div>          
                </div>
                {Spinner}
        </Container> </>
     
    )
    //</StyledForm>
    //<StyledForm onSubmit={handleSubmit}>
    //tyledInput type="text" value={username} onChange={e => usernameEntered(e)}
    //invalid={passwordInvalid}
    //StyledInput type="password" value={password} onChange={(e) => passwordEntered(e)
}

export default PassRecov;


