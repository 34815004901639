import Container from "../../common/Container";
import AdminContentBlockContent from "../../content/AdminContentBlockContent.json";
import AdminContentBlock from "src/components/AdminContentBlock";

const AdminPage = () => {

    return(
      <Container>
 <AdminContentBlock
        direction="left"
        title={AdminContentBlockContent.title}
        content={AdminContentBlockContent.text}
        icon=""
        id="admin"
      />

      </Container>

 
    )
}

export default AdminPage; 