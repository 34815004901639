import { useState } from 'react'

interface Step {
  label: string
  step: number
}

interface StepperProps {
  steps: Step[]
}

const Stepper: React.FC<StepperProps> = ({ steps }) => {
  const [activeStep, setActiveStep] = useState(5)

  const nextStep = () => {
    setActiveStep((prevStep) => prevStep + 5)
  }

  const prevStep = () => {
    setActiveStep((prevStep) => prevStep - 5)
  }

  const totalSteps = steps.length*4

  const width = `${(100 / (totalSteps - 5)) * (activeStep - 5)}%`

  return (
    <div className="mx-auto w-full max-w-2xl px-4 pb-10">
      <div className="before:transform-y-1/2 relative mt-14 flex justify-between before:absolute before:left-0 before:top-1/2 before:h-1 before:w-full before:bg-slate-200">
        {steps.map(({ step, label }) => (
          <div className="relative z-10" key={step}>
            <div
              className={`flex size-10 items-center justify-center rounded-full border-2 border-black-200 bg-white transition-all delay-200 ease-in ${
                activeStep >= step ? 'border-slate-400' : ''
              }`}
            >
              {activeStep > step ? (
                <div className="rotate-45 -scale-x-100 text-2xl font-semibold text-green-400">
                  L
                </div>
              ) : (
                <span className="text-lg font-medium text-zinc-400">
                  {step}
                </span>
              )}
            </div>
            <div className="absolute left-1/2 top-24 -translate-x-2/4 -translate-y-2/4">
              <span className="text-lg font-semibold text-black-400">
                {label}
              </span>
            </div>
          </div>
        ))}
        <div
          className="transform-y-1/2 absolute left-0 top-1/2 h-1 w-full bg-green-400 transition-all delay-200 ease-in"
          style={{ width: width }}
        ></div>
      </div>
      <div className="mt-28 flex justify-between">
        <button
          className="rounded-md border bg-gray-500 px-8 py-1.5 text-base font-medium text-white hover:bg-gray-600 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700"
          onClick={prevStep}
          disabled={activeStep === 1}
        >
          Previous
        </button>
        <button
          className="rounded-md border bg-gray-500 px-8 py-1.5 text-base font-medium text-white hover:bg-gray-600 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700"
          onClick={nextStep}
          disabled={activeStep === totalSteps}
        >
            Next
        </button>
      </div>
    </div>
  )
}

export default Stepper