import axios from "axios";
import { getQrcodeApiDomain } from "../../config";

const API_URL_CRUD = (getQrcodeApiDomain()+ "/api/");

export default function grantaward(name: string, qrcode: string) {
    return axios.post(API_URL_CRUD + "gaward", {
        name,
        qrcode
      });
     
    }