import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";

import { ContentBlockProps } from "../../common/ContentBlockCss/types";
import { SvgIcon } from "../../common/SvgIcon";
import {
  ContentSection,
  Content,
  ContentWrapper,
  ServiceWrapper,
  MinTitle,
  MinPara,
  StyledRow,
  Span,
  Large,
} from "../../common/ContentBlockCss/styles";
import { Button } from "../../common/Button";
import getuser from "../../common/SessionUser"
import readqr from "../../services/QrcodeScan"
import { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import ProgressBar from "./ProgreesBar"
import { steps } from "./steps";
import { center } from "src/pages/PrivecyPolicy/styles";



const UserContentBlock = ({
  title,
  content,
  section,
  t,
  id,
  direction,
  result,
  icon,
  error
}: ContentBlockProps) => {

  const [startScan, setStartScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");
  const [facingMode, setFacingMode] = useState("environment");

  async function  handleScan (result: any, error: any) {

    try {
    if (!!result) {
      setData(result.text);
      setStartScan(false);
      setLoadingScan(false);}
  }
  catch(error)
  {
      console.info(error);
    }
  }

  useEffect(() => {
    
    async function senddata() {
      if (data){
      let name
    //  let qrcode = "23EE5F5463"
     // let res;
     // console.log("scanned data", data)
        if ( name = await getuser()) {
        window.alert(` for test purpose user found ${name}`); 
        }
      try{
        if (await readqr(name,data))
        {
    
          window.alert(`20 Qrcodes has been linked to your account ${name}, you will get rewards as you following required procedures`);
        }
    }
    catch(err: any)
    { window.alert(`the sheet allready been added to other account`);
      console.log(err)}
      } 
      setData("");
    }
     
      senddata();
  }, [data]);


  return (
    <>
    <ContentSection>   
      <StyledRow
        justify="space-between"
        align="middle"
        id={id}
        direction={direction}
      >
        <Col lg={22} md={22} sm={22} xs={24}>
          <ContentWrapper>
            <h3>{t(title)}</h3>
            <Content>{t(content)}</Content>
            <Button
                onClick={() => {  
                  setData("");
                  setStartScan(!startScan);
                  setLoadingScan(!loadingScan);

                } }
               name="Scan now">{startScan ? t("Stop scan") : t("Scan now")}
              </Button>
          </ContentWrapper>
        </Col>
      </StyledRow>
      
      <div>
     <p></p>
     </div>
      {startScan && (
  <><select onChange={(e) => setFacingMode(e.target.value)}>
        <option value={"environment"}>Back Camera</option>
        <option value={"user"}>Front Camera</option>
      </select>
      <div>
     <p></p>
     </div>
      <QrReader
          constraints={{
            facingMode,
          }}
          videoStyle={{width: 300, height: 300, border: 'solid', borderWidth: '4px', borderColor: data.length === 0 ? 'green' : 'blue', }}
          scanDelay={500}
          onResult={handleScan}
          />
          </>
        )}
      
         <Col lg={11} md={11} sm={12} xs={24}>
            <SvgIcon src={icon} width="80%" height="80%" />
          </Col>
          
          Request a free sticky QRcode sheet 
          <Large to="/SheetRequest">{("here")}</Large>
          <ProgressBar steps={steps}/>
          </ContentSection>
          {loadingScan && <p>Loading</p>}
          {data !== "" && <p>{data}</p>}
           <div>
          <p></p>
          </div>
    </>
      
  );


};

export default withTranslation()(UserContentBlock);
