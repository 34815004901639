export const steps = [
  {
    label: '',
    step: 0,
  },
  {
    label: '',
    step: 5,
  },
  {
    label: 'Reward Progress',
    step: 10,
  },
  {
    label: '',
    step: 15,
  },
  {
    label: '',
    step: 20,
  },
]