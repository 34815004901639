import Input from '../../common/Input';
import { Container, StyledButton, FormGroup, Span, Large} from "../../common/ContentBlockCss/styles"
import { Styles } from "../../styles/styles";
import RecoverPass from "../../common/RecoverPass";
import  {LoadingSpinner}  from "../../common/LoadingSpinner"
import { useState } from 'react';
import qrcodeimg from "../../services/QrcodeGenerate"
import getuser from "../../common/SessionUser"



function SheetRequest() {
  interface IValues {
    name: string;
    address: string;
    postcode: string;
  }
  
  const initialValues: IValues = {
    name: "",
    address: "",
    postcode: "",
  };
  

  const [formState, setFormState] = useState<{
    values: IValues;
    errors: IValues;
  }>({
    values: { ...initialValues },
    errors: { ...initialValues },
  });
  
  const [loading, setLoading]   = useState(false);
  const [formvalid, setFormvalid]   = useState(false);
  
  




const Validation = () => {
  let errorMessage:string = ""; 
  if ((formState.values.name.length < 4) || (formState.values.postcode.length < 8) || (formState.values.address.length < 8)) {
    errorMessage = "Form should not be empty & Postcode must be at least 7 characters";
    setFormvalid(false)
   }
   else {setFormvalid(true)}  
   return <Span>{!formvalid? errorMessage: ""}</Span>;
}

    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {

      event.persist();
     const { name, value } = event.target;
     setFormState((prevState) => ({
       ...prevState,
       values: {
         ...prevState.values,
         [name]: value,
       },
       errors: {
         ...prevState.errors,
         [name]: "",
       },
     }));
   };
 
    
    const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        const loggedname = await getuser() //test purpose
         const values = formState.values;
         console.log("session name", loggedname);
         if (await qrcodeimg(loggedname, values.address,values.postcode))
          window.alert(`your QR code sheet has been generated successefuly ${values.name}`);
        else 
        window.alert(`Someting went wrong from ourside, no worries, try again ${values.name}`);
        setLoading(false);
        event.target.reset();
        
    }

  const Spinner = () =>{
   
if (loading)
  {return <LoadingSpinner/>;}   
  }
       
    return (
        <>
        <Container>
        <Styles />
        <FormGroup autoComplete="on" onSubmit={handleSubmit}>
                 <Input
                  type="text"
                  name="name"
                  placeholder="name"
                  value={ formState.values.name || ""} 
                  onChange={handleChange} /> 
                <Input
                  type="text"
                  name="address"
                  placeholder="Address"
                 value={ formState.values.address || ""} 
                  onChange={handleChange} /> 
                   <Input
                  type="text"
                  name="postcode"
                  placeholder="postcode"
                  value={ formState.values.postcode || ""} 
                  onChange={handleChange} /> 
                   <Validation />
                  <div>          
                </div>
                <StyledButton name="submit" disabled={!formvalid}>{("Submit")}</StyledButton>
                </FormGroup>
                <div>          
                </div>
                {Spinner}
        </Container> </>
     
    )
    //</StyledForm>
    //<StyledForm onSubmit={handleSubmit}>
    //tyledInput type="text" value={username} onChange={e => usernameEntered(e)}
    //invalid={passwordInvalid}
    //StyledInput type="password" value={password} onChange={(e) => passwordEntered(e)
}

export default SheetRequest;


